import React from 'react'
import {applyCustomAmpersands} from 'react-custom-ampersand'
import {brandColor} from '../../utils/colors'
import styles from './style.module.scss'

const Ampersand = () => {
  return (
    <span style={{
      color: brandColor,
      fontFamily: 'Playfair Display, serif',
      lineHeight: 0.9
    }}>
      &
    </span>
  )
}

export default ({ children }) => {
  return (
    <div className={styles.headingwrap}>
      <h1 style={{margin: 0}}>
        { applyCustomAmpersands(children, {}, Ampersand) }
      </h1>
    </div>
  )
}
